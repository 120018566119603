import {filterStringToFilterItems,filterItemsToString,prettifyFilterItems,groupObject,createInitialObject} from 'o365.modules.filterUtils.ts';
//import utilsData from 'o365.modules.utils.data.js';
import FilterTemplateItem from 'o365.modules.FilterTemplateItem.ts';
export default class FilterGroup{
    private _group:any;
    private _filterGroupName:string = "";
    private _objectGroup:Array<any> = [];
    private _filterString:string = "";
    private _prevString:string = "";
    private _prettyString:string = "";
    private _columns:Array<any>
    private _filterTemplate:string = "";
    private _prevFilterTemplate:string = "";
    error:string = "";

    newItem:FilterTemplateItem|null = null;
    
    get group(){
        return this._group;
    }

    get filterGroupName(){
        return this._filterGroupName
    }
    set filterGroupName(newValue: string) {
        this._filterGroupName = newValue;
    }

    get isComplete(){
        let isComplete = true;

        if(!this.groupList) return true;
        this.groupList.forEach(item=>{
            if(typeof item != "string" && item.editor && item.value == undefined){
                isComplete = false;
                //return false;
            }
        });
        return isComplete;
    }
    get groupList(){
        return this._objectGroup;
    }

    get filterTemplate(){
        return this.template;
    }

    set template(pString){
        this._filterTemplate = pString;
    }

    set prevFilterTemplate(pValue:string){
        this._prevFilterTemplate = pValue;
    }
    get prevFilterTemplate(){
        return this._prevFilterTemplate;
    }
    get template(){
        return this._filterTemplate;
    }

    get hasChanges(){
        return this._filterString !== this._prevString;
    }
    get templateHasChanges(){
        return this._filterTemplate.replaceAll(" ","") !== this._prevFilterTemplate.replaceAll(" ","");
    }

    get prettyString(){
        return this._prettyString;
    }
    get filterString(){
        return this._filterString;
    }

    get columns(){
        return this._columns;
    }
    set columns(pColumns){
      
        if(pColumns.length){
            this._columns = pColumns;
        }
        
    }

    get toJSON(){
        return cloneFilter(this._group,true);
    }
    
    constructor(pColumns:Array<any>){
        this._columns = pColumns;
    
    }

  

    async updateFilterGroupFromObject(pData:any){
        this._filterTemplate = pData.FilterCriteria;
        this._prevFilterTemplate = pData.FilterCriteria;
        try{
            this._updateGroup(JSON.parse(pData.FilterTemplate));
        }catch{
            const vObj = await filterStringToFilterItems(pData.FilterCriteria);
            this._updateGroup(vObj);
        }
        this._prevString = this._filterString;
        this._prevFilterTemplate = this.template;
        
    }

    async updateFilterGroup(pTemplate:string){
        this._filterTemplate = pTemplate;
        if(!this.hasChanges) return;
        this._prevFilterTemplate = pTemplate;
        const vObj = await filterStringToFilterItems(pTemplate);

       
        this._updateGroup(vObj);
        this._prevString = this._filterString;
        
    }

    add(pObj:any){
        if(!this.template){
            this._group = createInitialObject(new FilterTemplateItem(pObj.item))
        }else{
            if(this._group.items[0].items){
                this._group.items[0].items.push(new FilterTemplateItem(pObj.item));
            }else{
                this._group.items.push(new FilterTemplateItem(pObj.item));
            }
        }
       
        this.template = filterItemsToString(cloneFilter(this.group));

         
        this._groupArray();
        

      
        
    }

    private _groupArray(){
        const vGroup = groupObject(this.group);
     
        if(vGroup.constructor == Array){
            this._objectGroup = groupObject(this.group).flat();
        }else{
            this._objectGroup = [groupObject(this.group)];
        }

        this._filterString = filterItemsToString(this.group);

        this._prettyString = prettifyFilterItems(this.group, this._columns);
    }

    private _updateGroup(pObj:any){
        this._group = this._updateGroupWithItems(pObj);
        
       this._groupArray();
    }

    async updateTemplate(){
       // if(!this.hasChanges) return true;

        if(!this.template){
            this.error = "";
            this._filterString = "";
            this._prettyString = "";
            this._objectGroup = [];
            this._group = [];
            return null;
        }

       
        const vResults = await filterStringToFilterItems(this.template);

       
        if(vResults.type === "group"){
            this._updateGroup(vResults);
            

            this.error = "";
            return vResults;
        }else{
            this.error = "Failed to parse"
            return null;
        }
      
    }

    updateFilterString(){
        console.log("filter String updated");
        const vCopy = cloneFilter(this.group,true);
         /*this._filterString = filterItemsToString(this.group);
         this._prettyString = prettifyFilterItems(this.group, this._columns);*/
         this._filterString = filterItemsToString(vCopy);
         this._prettyString = prettifyFilterItems(vCopy, this._columns);
    } 

    private _updateGroupWithItems(pObj:any){
        if(pObj.hasOwnProperty("items")){
            pObj["items"].forEach((item:any,index:number)=>{
                if(item.hasOwnProperty("column")){
                    let vColumn = this._columns.find(x=>x.column == item.column);

                    if(vColumn){
                        item.caption = vColumn.caption;
                        item.valueType = vColumn.type;
                    }else{
                        vColumn = this._columns.find(x=>x.caption == item.column);
                        if(vColumn){
                            item.caption = item.column;
                            item.valueType = vColumn.type;
                            item.column = vColumn.column;
                        }
                    }
                    pObj["items"][index] = new Proxy(new FilterTemplateItem(item), {
                        set: (obj, prop, value) => {
                            Reflect.set(obj, prop, value);
                            if (prop == "excluded") this.updateFilterString();
                            if (prop == "selectedValue") this.updateFilterString();
                            if (prop == "operator") this.updateFilterString();
                            return true;
                        }
                    });
                    if(pObj["items"][index].isColumn){
                        let vValueColumn = this._columns.find(x=>x.column == pObj["items"][index].value.replace("[","").replace("]",""));
                        if(vValueColumn){
                            pObj["items"][index].displayValue = "["+vValueColumn.caption+"]";
                        }else{
                            vValueColumn = this._columns.find(x=>x.caption == pObj["items"][index].value.replace("[","").replace("]",""));
                            if(vValueColumn){
                                pObj["items"][index].displayValue = pObj["items"][index].value;
                                pObj["items"][index].value = "["+vValueColumn.column+"]";
                            }
                            
                        }
                    }
                   
                }else if(item.hasOwnProperty("items")){
                    item = this._updateGroupWithItems(item)
                }

            })

            return pObj;
        }
    }

   
}
const cloneFilter = (obj:any,forSaving:boolean = false) =>{

    let copy:any;

    if (null == obj || "object" != typeof obj) return obj;


    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = cloneFilter(obj[i],forSaving);
        }
        return copy;
    }
     if (obj instanceof FilterTemplateItem) {
        if(forSaving){
             return obj.item;
        }
        return obj.itemForFilter;
    }

    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = cloneFilter(obj[attr],forSaving);
        }
        return copy;
    }
}
// RECOMPILE
